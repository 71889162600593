import bg from '../assets/images/shape/pattern-2.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ami from '../assets/images/clients/AMI_ENERGY.jpg';
import diavaz from '../assets/images/clients/DIAVAZ.jpg';
import mepi from '../assets/images/clients/MEPI.jpg';
import pemex from '../assets/images/clients/PEMEX.jpg';
import innophos from '../assets/images/clients/INNOPHOS.jpg';
import indelpro from '../assets/images/clients/indelpro.jpg';

export const Clients = () => {
  return (
    <>
      <section className="testimonial-section" id="clients">
            <div className="pattern-layer" style={{backgroundImage: `url(${bg})`}}></div>
            <div className="large-container">
                <div className="sec-title centred">
                    <p>Clientes</p>
                    <h2>Algunos de nuestros clientes</h2>
                </div>
                <OwlCarousel
                    className='clients-carousel owl-carousel owl-theme owl-nav-none owl-dots-none'
                    loop={true}
                    margin={30}
                    nav={false}
                    smartSpeed={3000}
                    autoplay={true}
                    navText={[ '<span className="fas fa-angle-left"></span>', '<span className="fas fa-angle-right"></span>']}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        480:{
                            items:2
                        },
                        600:{
                            items:3
                        },
                        800:{
                            items:4
                        },			
                        1200:{
                            items:5
                        }
                    }}
                >
                    <figure className="clients-logo-box"><img src={ami} alt="" /></figure>
                    <figure className="clients-logo-box"><img src={diavaz} alt="" /></figure>
                    <figure className="clients-logo-box"><img src={mepi} alt="" /></figure>
                    <figure className="clients-logo-box"><img src={pemex} alt="" /></figure>
                    <figure className="clients-logo-box"><img src={innophos} alt="" /></figure>
                    <figure className="clients-logo-box"><img src={indelpro} alt="" /></figure>
                </OwlCarousel>
            </div>
        </section>
        <section className="clients-section bg-color-1">
            <div className="auto-container">
            </div>
        </section>
    </>
  );
};