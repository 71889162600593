import { Modal } from "react-bootstrap";

export const ModalDescription = ({ data, show, onClose }) => {
  const { title } = data || {};
  const content = data?.content || [];
  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <ul>
            {content.length > 0
              ? content.map((item, index) => (
                  <li style={{listStyleType: 'circle'}} key={index}>{item}</li>
                ))
              : null
            }
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onClose}>Cerrar</button>
      </Modal.Footer>
    </Modal>
  );
}