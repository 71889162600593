import about from '../assets/images/resource/about-1.jpg';
import { data } from '../data/data.ts';

export const About = () => {
  return (
    <section className="about-section" id="about">
        <div className="auto-container">
            <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image_block_1">
                        <div className="image-box">
                            <figure className="image"><img src={about} alt="" /></figure>
                            <div className="image-content">
                                <h2>+25</h2>
                                <h5>Años nos respaldan</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="content_block_1">
                        <div className="content-box">
                            <div className="sec-title">
                                <p>Sobre Contraincendio Maritimo de Campeche</p>
                                <h2>Con más de 25 años de experiencia</h2>
                            </div>
                            <div className="text">
                                <p>
                                    Las personas que integramos la familia <b>Contraincendio Maritimo de Campeche</b> somos, con toda seguridad su aliado. <br />
                                    Desde su origne, hemos dado prioridad a impulsar el entorno, para fomentar el desarrollo humano y profesional de las personas. Solo así,
                                    con el compromiso en equipo, aportaremos el máximo a la satisfacción de los clientes.
                                </p>
                            </div>
                            {/* <div className="inner-box clearfix">
                                <div className="single-item">
                                    <i className="flaticon-bonfire"></i>
                                    <h4>Think of fire <br />before it starts</h4>
                                </div>
                                <div className="single-item">
                                    <i className="flaticon-matches"></i>
                                    <h4>Don’t play with matches</h4>
                                </div>
                            </div>
                            <ul className="list-item clearfix">
                                <li>If you are going to use a passage of you need</li>
                                <li>Lorem ipsum available, but the majority have suffered</li>
                            </ul> */}
                            <div className="support-box">
                                <i className="flaticon-call"></i>
                                <p>Llamanos</p>
                                <h4><a href="tel:8336143324">{data.phone}</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};