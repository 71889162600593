import { useState } from 'react';
import bg from '../assets/images/background/service-bg-1.jpg';
import service1 from '../assets/images/service/service-1.jpg';
import service2 from '../assets/images/service/service-2.jpg';
import service3 from '../assets/images/service/service-3.jpg';
import service4 from '../assets/images/service/service-4.jpg';
import service5 from '../assets/images/service/service-5.jpg';
import { services } from '../data/services.ts';
import { ModalDescription } from './ModalDescription.js';

export const Services = () => {
    const images = [service1, service2, service3, service4, service5];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [modalData, setModalData] = useState(null);
  return (
    <>
        <section className="service-section centred bg-color-1" id="service">
            <div className="bg-layer" style={{backgroundImage: `url(${bg})`}}></div>
            <div className="auto-container">
                <div className="sec-title">
                    <p>Servicios</p>
                    <h2>Servicios que ofrecemos</h2>
                </div>
                <div className="row clearfix">
                    {services.map((service, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 service-block" style={{marginTop: 5 + 'px'}} key={index}>
                                <div className="service-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div className="inner-box">
                                        <figure className="image-box"><img src={images[index]} alt="" /></figure>
                                        <div className="text">
                                            <h2>{service.title}</h2>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="inner">
                                                <h3><a href="service-details.html">{service.title}</a></h3>
                                                <p>{service.description}</p>
                                                <div className="link">
                                                    <a
                                                        style={{cursor: 'pointer'}} 
                                                        onClick={() => {
                                                            setModalData(service);
                                                            setShow(true);
                                                        }}
                                                    >Más</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
        <ModalDescription show={show} onClose={handleClose} data={modalData} />
    </>
  );
};