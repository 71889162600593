import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import bg1 from '../assets/images/banner/banner-1.jpg';
import bg2 from '../assets/images/banner/banner-2.jpg';
import bg3 from '../assets/images/banner/banner-3.jpg';

export const Banner = () => {
  return (
    <section className="banner-section style-one" id='home'>
      <div className="pattern-layer"></div>
      <OwlCarousel className="banner-carousel owl-theme owl-carousel owl-nav-none" 
        loop={true} 
        nav={true} 
        items={1}
        animateOut={'fadeOut'}
        animateIn={'fadeIn'}
        autoplay={6000}
        smartSpeed={1000}
        active={true}
        navText={['<i className="flaticon-left-arrow"></i>', '<i className="flaticon-right-arrow"></i>']}
        margin={0}
        >
        <div className="slide-item">
              <div className="image-layer" style={{backgroundImage: `url(${bg1})`}}></div>
              <div className="auto-container">
                  <div className="content-box">
                      <h1>Estamos aquí para salvar vidas</h1>
                      <p>Contamos con personal altamente capacitado para poder apoyarte en alguna incidencia.</p>
                      <div className="btn-box">
                          <a href="#service" className="theme-btn-one">Ir a Servicios</a>
                      </div>
                  </div> 
              </div>
          </div>
          <div className="slide-item bg-left">
              <div className="image-layer" style={{backgroundImage: `url(${bg2})`}}></div>
              <div className="auto-container">
                  <div className="content-box">
                      <h1>Estamos aquí para capacitarte</h1>
                      <p>Tener un equipo capacitado y certificado te ayudará a poder atender de una forma oportuna cualquier incidente.</p>
                      <div className="btn-box">
                          <a href="#service" className="theme-btn-one">Ir a Servicios</a>
                      </div>
                  </div>  
              </div>
          </div>
          <div className="slide-item bg-left">
              <div className="image-layer" style={{backgroundImage: `url(${bg3})`}}></div>
              <div className="auto-container">
                  <div className="content-box">
                      <h1>Estamos aquí para apoyarte</h1>
                      <p>Tenemos una amplia gama equipo que podemos rentarte en caso de tener un incidente.</p>
                      <div className="btn-box">
                          <a href="#service" className="theme-btn-one">Ir a Servicios</a>
                      </div>
                  </div>  
              </div>
          </div>
      </OwlCarousel>
    </section>
  );
};