import bg from '../assets/images/background/footer-bg-1.jpg';
import { data } from '../data/data.ts';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '40vh',
};
const center = {
  lat: 22.247798919677734, // default latitude
  lng: -97.87443542480469, // default longitude
};

export const Footer = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDMCd4AJhZ2MKeXHFFb7fjq2aLxZK1fWgQ',
        libraries,
    });
    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }
  return (
    <footer className="main-footer" style={{backgroundImage: `url(${bg})`}}>
            <figure className="footer-logo"><img src="assets/images/footer-logo.png" alt="" /></figure>
            <div className="footer-top">
                <div className="auto-container">
                    <div className="widget-section">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget about-widget">
                                    <div className="widget-title">
                                        <h4>{data.name}</h4>
                                    </div>
                                    <div className="text">
                                        <p>Más de 25 años nos respaldan</p>
                                    </div>
                                    <div className="support-box">
                                        <i className="flaticon-call"></i>
                                        <p>Llámanos</p>
                                        <h4><a href="tel:8336143324">{data.phone}</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget map-widget">
                                    <div className="widget-title">
                                        <h4>Encuentranos</h4>
                                    </div>
                                    <div className="map-inner">
                                        <div className="map-container" style={{ height: '40vh', width: '100%' }}>
                                            <GoogleMap
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={18}
                                                center={center}
                                            >
                                                <Marker position={center} />
                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="auto-container clearfix">
                    <div className="copyright pull-left">
                        <p><i className='fas fa-code'></i> with <i className="fas fa-heart"></i> by <a href="https://uirtusit.com" target={'_blank'} rel='noreferrer'>UirtusIT</a></p>
                    </div>
                    <ul className="footer-social clearfix pull-right">
                        <li><a href={data.facebookUrl} target={'_blank'} rel='noreferrer'><i className="fab fa-facebook-square"></i></a></li>
                    </ul>
                </div>
            </div>
        </footer>
  );
};