import { About } from './components/About';
import { Banner } from './components/Banner';
import { Clients } from './components/Clients';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import Navbar from './components/Navbar';
import { Products } from './components/Products';
import { Services } from './components/Services';
import { Top } from './components/Top';

function App() {
  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <Services />
      <Products />
      <Clients />
      <Contact />
      <Footer />
      <Top />
    </>
  );
}

export default App;
