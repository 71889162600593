import {data} from '../data/data.ts';
import logo from '../assets/logo/contraincendio.svg';
function Navbar() {

    const list = document.querySelectorAll(".list");

    function activeLink() {
    list.forEach((item) => item.classList.remove("current"));
    this.classList.add("current");
    }
    list.forEach((item) => item.addEventListener("click", activeLink));
    
    const handleMenuToggleBtn = () => {
        const btnClose = document.querySelector('.close-btn');
        const menuBackdrop = document.querySelector('.menu-backdrop');
        const mobileMenu = document.querySelector('.mobile-nav-toggler');
        
        mobileMenu.addEventListener('click', function () {
            document.querySelector('body').classList.add('mobile-menu-visible');
        });

        btnClose.addEventListener('click', function () {
            document.querySelector('body').classList.remove('mobile-menu-visible');
        });
        menuBackdrop.addEventListener('click', function () {
            document.querySelector('body').classList.remove('mobile-menu-visible');
        });
    }
  return (
    <>
      <header className="main-header">
            <div className="header-top">
                <div className="auto-container">
                    <div className="top-inner clearfix">
                        <div className="text pull-left"><p>Contraincendio Maritimo de Campeche</p></div>
                        <div className="top-right pull-right">
                            <ul className="social-links clearfix">
                                <li><a href={data.facebookUrl} target='_blank' rel='noreferrer'><i className="fab fa-facebook-square"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-lower">
                <div className="auto-container">
                    <div className="outer-box clearfix">
                        <div className="logo-box pull-left">
                            <figure className="logo"><a href="/"><img src={logo} alt="" style={{width: 42 + 'px'}} /></a></figure>
                        </div>
                        <div className="menu-area pull-left">
                            <div className="mobile-nav-toggler" onClick={handleMenuToggleBtn}>
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                                <i className="icon-bar"></i>
                            </div>
                            <nav className="main-menu navbar-expand-md navbar-light">
                                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul className="navigation scroll-nav clearfix">
                                        <li className="list current"><a href="#home">Inicio</a></li> 
                                        <li className='list'><a href="#about">Nosotros</a></li>
                                        <li className='list'><a href="#service">Servicios</a></li>
                                        <li className='list'><a href="#products">Productos</a></li>
                                        <li className='list'><a href="#clients">Clientes</a></li>
                                        <li className='list'><a href="#contact">Contacto</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="support-box pull-right">
                            <i className="flaticon-call"></i>
                            <p>Llámanos</p>
                            <h4><a href="tel:8336143324">{data.phone}</a></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-header">
                <div className="auto-container">
                    <div className="outer-box clearfix">
                        <div className="logo-box pull-left">
                            <figure className="logo"><a href="/"><img src={logo} alt="" style={{width: 42 + 'px'}} /></a></figure>
                        </div>
                        <div className="menu-area pull-left">
                            <nav className="main-menu clearfix">
                                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul className="navigation scroll-nav clearfix">
                                    <li className="list current"><a href="#home">Inicio</a></li> 
                                        <li className='list'><a href="#about">Nosotros</a></li>
                                        <li className='list'><a href="#service">Servicios</a></li>
                                        <li className='list'><a href="#products">Productos</a></li>
                                        <li className='list'><a href="#clients">Clientes</a></li>
                                        <li className='list'><a href="#contact">Contacto</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="support-box pull-right">
                            <i className="flaticon-call"></i>
                            <p>Llámanos</p>
                            <h4><a href="tel:8336143324">{data.phone}</a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn"><i className="fas fa-times"></i></div>
            
            <nav className="menu-box">
                <div className="nav-logo text-center"><a href="/"><img src={logo} alt="" title="" style={{width: 80 + 'px'}} /></a></div>
                <div className="menu-outer">
                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul className="navigation scroll-nav clearfix">
                            <li className="list current"><a href="#home">Inicio</a></li>
                            <li className='list'><a href="#service">Servicios</a></li>
                            <li className='list'><a href="#products">Productos</a></li>
                            <li className='list'><a href="#clients">Clientes</a></li>
                            <li className='list'><a href="#contact">Contacto</a></li>
                        </ul>
                    </div>
                </div>
                <div className="contact-info">
                    <h4>Información de Contacto</h4>
                    <ul>
                        <li>{data.address}</li>
                        <li><a href="tel:8336143324">{data.phone}</a></li>
                        <li><a href="mailto:alejandra.garcia@cimc.com.mx">alejandra.garcia@cimc.com.mx</a></li>
                    </ul>
                </div>
                <div className="social-links">
                    <ul className="clearfix">
                        <li><a href={data.facebookUrl} target='_blank' rel='noreferrer'><span className="fab fa-facebook-square"></span></a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </>
  );
};

export default Navbar;