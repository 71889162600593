import { data } from "../data/data.ts";

export const Products = () => {
  return (
    <section className="funfact-section" id="products">
    <div className="auto-container">
        <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_3">
                    <div className="content-box">
                        <div className="sec-title">
                            <p>Nuestros Productos</p>
                            <h2>Contamos con productos de primer nivel</h2>
                        </div>
                        <div className="text">
                            <p>
                                Contamos con una amplia gama de productos de primer nivel, con la mejor calidad y al mejor precio.
                            </p>
                        </div>
                        <div className="lower-box">
                            <h4>Descarga nuestro catálogo</h4>
                            <a href={data.products} style={{cursor: 'pointer'}} download target={'_blank'} rel='noreferrer'>Descargar</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
                <div className="content_block_4">
                    <div className="counter-block">
                        <div className="counter-block-one">
                            <div className="icon-box"><i className="flaticon-inspection"></i></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="6780">2,350</span>
                            </div>
                            <p>Proyectos atendidos</p>
                        </div>
                        <div className="counter-block-one">
                            <div className="icon-box"><i className="flaticon-charity"></i></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="3800">+200</span>
                            </div>
                            <p>Clientes satisfechos</p>
                        </div>
                        <div className="counter-block-one">
                            <div className="icon-box"><i className="flaticon-round-table"></i></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="2490">+2,000</span>
                            </div>
                            <p>Capacitacion impartidas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  );
};