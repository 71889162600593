export const services = [
  {
    title: 'Ventas de equipo',
    content: [
      'Camiones contra incendio.',
      'Monitores contra incendio.',
      'Cámaras de espuma, concentrado espumante.',
      'Mangueras contra incendio, conexiones storz, reducciones.',
      'Boquillas, aspersores, válvulas',
      'Equipo estructural para bomberos completo y ropa antiácidos encapsulados.'
    ],
    description: 'Podemos ofrecerte una gran variedad de productos para la prevención y combate de incendios.'
  },
  {
    title: 'Cursos, capacitaciones y certificados',
    content: [
      'Teoría del fuego, dínamica del incendio y agentes de extinción.',
      'Clasificación de riesgos y enfoques de diseño.',
      'Principios de ingeniería contra incendios.',
      'Diseño e instalación de sistemas de protección contra incendio.',
      'Diseño e instalación de cuarto de bombas y reserva de agua contra incendio.',
      'Diseño e instalación de red de agua contra incendio. Tubería vertical edificios.',
      'Diseño e instalación de sistemas automáticos de rociadores de agua.',
      'Diseño e instalación de sistemas automáticos de agua pulverizada diluvio.',
      'Diseño e instalación de sistemas manuales y automáticos de espuma.',
      'Diseño e instalación de sistema de alarma de incendio y señalización.',
      'Certificación de especialistas técnicos en Respuesta a Emergencias en atención de Incendios Industriales'
    ],
    description: 'Impartimos cursos y capacitaciones para que puedas estar preparado en caso de un incendio.'
  },
  {
    title: 'Asesoría, ingeniería y construcción de redes contraincendio',
    content: [
    ],
    description: 'Construcción e ingeniería de redes contraincendio.'
  },
  {
    title: 'Renta de equipos',
    content: [
      'Hydroguard (protección de pozos tierra).',
      'Cortina (protección de plataformas marinas).',
      'Bomba y monitor de 6,000 GPM.',
      'Monitor Williams Fire Modelo Ambassador De 6,000gpm',
      'Personal especialista en incendio industrial.'
    ],
    description: 'Tenemos equipos especializados para renta en caso de incendios.'
  },
  {
    title: 'Asistencia en incendios',
    content: [
      'Personal Especializado 24/7.',
      'Equipamiento / Materiales.',
      'Soporte en Incendios Industriales.'
    ],
    description: 'Ponemos a tu disposición personal especializado en caso de incendios.'
  }
];