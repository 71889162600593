import {data} from '../data/data.ts';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
    const [formValues, setFormValues] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        subject: '',
        message: '',
    });

    const form = useRef(null);

    const handleInputChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm('service_vazdj88', 'template_1wovl3s', form.current, {
                publicKey: '7alEWUU1quiq5jDwP'
            })
            .then(
                (result) => {
                    console.log(result.text);
                    alert('Mensaje enviado correctamente');
                    const form = document.getElementById('contact-form');
                    form.reset();
                },
                (error) => {
                    console.log(error.text);
                    alert('Ocurrió un error al enviar el mensaje');
                }
            );
    };
  return (
    <section className="contact-section" id="contact">
            <div className="auto-container">
                <div className="sec-title centred">
                    <br />
                    <p>Contacto</p>
                    <h2>Ponte en contacto con nosotros</h2>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                        <div className="content_block_6">
                            <div className="info-inner">
                                <p>Déjanos un mensaje, llámanos o visítanos, estámos para servirte</p>
                                <ul className="info-list clearfix">
                                    <li>
                                        <i className="flaticon-telephone" style={{marginTop: -15 + 'px'}}></i>
                                        <p><a href="tel:8336143324">{data.phone}</a><br /></p>
                                    </li>
                                    <li>
                                        <i className="fab fa-whatsapp" style={{marginTop: -15 + 'px'}}></i>
                                        <p>{data.whatsapp}</p>
                                    </li>
                                    <li>
                                        <i className="flaticon-email"></i>
                                        <p><a href={`mailto:${data.contactEmail}`}>{data.contactName}</a><br /><a href={`mailto:${data.contactEmail}`}>{data.contactEmail}</a></p>
                                    </li>
                                    <li>
                                        <i className="flaticon-pin"></i>
                                        <p>{data.address}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12 form-column">
                        <div className="form-inner">
                            <form ref={form} onSubmit={handlerSubmit} id="contact-form" className="default-form"> 
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="user_name" placeholder="Tú Nombre *" required="true" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="email" name="user_email" placeholder="Tú Email *" required="true" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                        <input type="text" name="user_phone" required="true" placeholder="Tú Teléfono *" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                        <input type="text" name="subject" required="true" placeholder="Asunto *" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <textarea name="message" required placeholder="Tú Mensaje *" onChange={handleInputChange}></textarea>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                        <button className="theme-btn-one" type="submit" name="submit-form">Enviar comentario</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
};